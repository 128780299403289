<template>
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" no-local-sorting @sort-changed="sortChanged"
        v-if="items.length">
      <template #cell(state)="data">
        <span v-b-tooltip="{trigger: istouch()?'click':'hover', delay: 10}" :title="$t('res.s.'+data.value.toLowerCase())"><Icon :type="data.value" :isButton="false" /></span>
      </template>
      <template #cell(startAt)="data">
        <span>{{ getDate(data.value) }}</span>
      </template>
      <template #cell(uuid4)="data">
        <ButtonSet :isDd="isXs" :useCols="false" :suffix="suffix" :id="items.indexOf(data.item)"
            :item="data.item" :showCancel="showCancel" :showDelete="showDelete" :showResolve="showResolve" :showProtest="showProtest"
            @success="onConfirmation()" :saveResult="true"
            :beforeShow="showShow" beforeVariant="primary" :beforeTo="`/reservations/`+data.item.uuid4" beforeSuffix="Show" beforeIcon="shw">
          <template #before>
            {{ $t('btn.shw') }}
          </template>
        </ButtonSet>
      </template>
    </b-table>
    <b-row fluid v-if="items.length">
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
    <b-card v-if="!items.length">
      <b-link v-if="noHitLink" :to="noHitLink">{{ noHitText }}</b-link>
      <span v-else>{{ noHitText }}</span>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';
import ButtonSet from '@/components/reservations/ButtonSet';

export default {
  data() {
    return {
      sortBy: 'startAt',
      sortDesc: false,
      // items and pagination would need to be moved to store to survive a "back" from showDetails
      items: [],
    }
  },
  created() {
    // values would need to be moved to store to survive "F5"
    [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.query, this.sortDesc, this.sortBy);
    Query.setSort(this.query, this.sortDesc, this.sortBy, 'uuid4');
    this.getResData();
  },
  computed: {
    fields() {
      return [
        { key: 'state', label: '', class: 'px-2 px-sm-3', thStyle: 'width: 25px;' },
        { key: 'customerName', label: this.$t('tab.l'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: 66%;', sortable: true },
        { key: 'startAt', label: this.$t('tab.d'), class: 'px-1 px-sm-3', thStyle: 'width: 34%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
  },
  methods: {
    onConfirmation() {
      // no pagination change necessary: state change does not affect order, delete will auto-update, add does not happen
      this.getResData(); // update full table, maybe just delete one line better
    },
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      Query.setSort(this.query, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.query, null, this.uiSett.visiblePerPage);
      this.getResData();
    },
    changePage(nextString) {
      Query.setPagi(this.query, nextString, this.uiSett.visiblePerPage);
      this.getResData();
    },
    getDate(v) {
      if (!v) return '';
      return this.$d(utcToZonedTime(v+'Z', this.tz), 'ds');
    },
    getResData() {
      REST.get('/users/' + this.$store.getters.uuid + '/reservations', this.getFormattedQuery()
        ).then(resp => {
          this.items = resp.d.data;
          if (this.showPagination) {
            this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
              if (resp.d.links.next !== '') {
                this.$refs.pagi.update(resp.d.links.next);
              }
              this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
            });
          }
        }).catch(e => {
          this.items = [];
          // catch 404 warning: user may not have added favs yet
          if (e.code != 404) {
            this.$store.commit('showWarn',e.message);
          }
          // if (this.showPagination) {
          //   this.$nextTick(() => {
              this.$emit('loaded', { hasContent: false, hasMore: false });
          //   });
          // }
        })
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.query};
      return { params: Query.replaceMagic(newQuery) };
    },
    istouch() {
      return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    },
  },
  // filters: {
  //   firstChar: function(value) {
  //     if (!value) return '';
  //     return value.toString().substring(0,1);
  //   }
  // },
  props: {
    query: {
      type: Object,
      required: true,
    },
    noHitText: {
      type: String,
      default: 'No reservations. Add a new one!',
    },
    noHitLink: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      required: true,
    },
    showShow: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showResolve: {
      type: Boolean,
      default: false,
    },
    showProtest: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ButtonSet,
    Pagination,
    Icon,
  },
}
</script>
