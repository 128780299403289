<template>
  <div>
    <ReservationsUserTable v-if="isUser" suffix="resTabCnlU"
        :query="resTabDefU"
        showShow showDelete showPagination
        :noHitText="$t('res.rcno')"
        noHitLink="/reservations/new" />
    <ReservationsCustomerTable v-if="!isUser" suffix="resTabCnlC"
        :query="resTabDefC"
        showShow showDelete showPagination
        :noHitText="$t('res.rcno')" />
  </div>
</template>

<script>
import ReservationsUserTable from '@/components/reservations/UserTable';
import ReservationsCustomerTable from '@/components/reservations/CustomerTable';

export default {
  data() {
    return {
      resTabDefU: { q: 'state:in:CANCELED;REJECTED,startAt:ge:{T-7}', s: '+startAt', p: '10' },
      resTabDefC: { q: 'state:in:CANCELED;REJECTED,userUuid4:n:{U},startAt:ge:{T-7}', s: '+startAt', p: '10' },
    };
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  components: {
    ReservationsUserTable,
    ReservationsCustomerTable,
  },
}
</script>
